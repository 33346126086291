<!-- Start Footer Area -->
<footer
    class="footer-area"
    [ngClass]="{'d-none': router.url === '/demo-7' || router.url === '/personal' || router.url === '/business' || router.url === '/how-it-works' || router.url === '/about-us-2' || router.url === '/contact-2'}"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-footer-widget">
                    <div class="logo mb-4">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                    </div>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-footer-widget pr-5">
                    <h3>Company</h3>

                    <ul class="list">
                        <li>
                            <a style="cursor: pointer;" (click)="triggerScrollTo('mission')" class="nav-link">Mission</a>
                        </li>
                        <li>
                            <a style="cursor: pointer;" (click)="triggerScrollTo('goals')" class="nav-link">Goals</a>
                        </li>
                        <li>
                            <a style="cursor: pointer;" (click)="triggerScrollTo('journey')" class="nav-link">Journey</a>
                        </li>
                        <li>
                            <a style="cursor: pointer;" (click)="triggerScrollTo('contact')" class="nav-link">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="footer-contact-info">
                        <li><span>Location:</span> ul. Igncego Mościckiego 1, <br> 24-100 Puławy, Poland</li>
                        <li><span>Email:</span> <a href="mailto:Klob@gmail.com"> contact@sensorai.eu</a></li>
                        <li><span>Phone:</span> <a href="tel:+321984754"> + 48 000 000 000</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="map-image"><img src="assets/img/map.png" alt="map"></div>
</footer>

<footer
    class="ctp-footer-area pt-100 d-none"
    [ngClass]="{'d-block': router.url === '/demo-7' || router.url === '/personal' || router.url === '/business' || router.url === '/how-it-works' || router.url === '/about-us-2' || router.url === '/contact-2'}"
>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="ctp-footer-widget">
                    <div class="logo mb-3">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="ctp-footer-widget">
                    <h3>Company</h3>
                    <ul class="links">
                        <li>
                            <a routerLink="/about-two">Mission</a>
                        </li>
                        <li>
                            <a routerLink="/how-it-works">Goal</a>
                        </li>
                        <li>
                            <a routerLink="/">Journey</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="ctp-footer-widget">
                    <h3>Support</h3>
                    <ul class="links">
                        <li>
                            <a routerLink="/">Terms and conditions</a>
                        </li>
                        <li>
                            <a routerLink="/">Privacy policy</a>
                        </li>
                        <li>
                            <a routerLink="/contact-two">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="ctp-footer-widget">
                    <h3>Need help?</h3>
                    <ul class="info">
                        <li>
                            <span>Location: </span>
                            27 Division St, New York, NY 10002, USA
                        </li>
                        <li>
                            <span>Email: </span>
                            <a href="mailto:klob@gmail.com">klob@gmail.com</a>
                        </li>
                        <li>
                            <span>Email: </span>
                            <a href="tel:321984754">+ (321) 984 754</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>© Klob is proudly created by <a href="https://envytheme.com/" target="_blank"> EnvyTheme</a></p>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i></div>
