import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './demos/default/default.component';
import { DemoTwoComponent } from './demos/demo-two/demo-two.component';
import { DemoThreeComponent } from './demos/demo-three/demo-three.component';
import { AboutComponent } from './pages/about/about.component';
import { FeaturesComponent } from './pages/features/features.component';
import { TeamComponent } from './pages/team/team.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FaqComponent } from './pages/faq/faq.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SigninComponent } from './pages/signin/signin.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DemoFourComponent } from './demos/demo-four/demo-four.component';
import { DemoFiveComponent } from './demos/demo-five/demo-five.component';
import { DemoSixComponent } from './demos/demo-six/demo-six.component';
import { BlogRightSidebarComponent } from './pages/blog-right-sidebar/blog-right-sidebar.component';
import { DemoSevenComponent } from './demos/demo-seven/demo-seven.component';
import { BusinessComponent } from './pages/business/business.component';
import { PersonalComponent } from './pages/personal/personal.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { AboutTwoComponent } from './pages/about-two/about-two.component';
import { ContactTwoComponent } from './pages/contact-two/contact-two.component';

const routes: Routes = [
    {path: '', component: DefaultComponent},
    {path: 'demo-2', component: DemoTwoComponent},
    {path: 'demo-3', component: DemoThreeComponent},
    {path: 'demo-4', component: DemoFourComponent},
    {path: 'demo-5', component: DemoFiveComponent},
    {path: 'demo-6', component: DemoSixComponent},
    {path: 'demo-7', component: DemoSevenComponent},
    {path: 'about-us', component: AboutComponent},
    {path: 'about-us-2', component: AboutTwoComponent},
    {path: 'business', component: BusinessComponent},
    {path: 'personal', component: PersonalComponent},
    {path: 'how-it-works', component: HowItWorksComponent},
    {path: 'features', component: FeaturesComponent},
    {path: 'team', component: TeamComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-right-sidebar', component: BlogRightSidebarComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'signup', component: SignupComponent},
    {path: 'signin', component: SigninComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'contact-2', component: ContactTwoComponent},
    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }