<!-- Start Page Title Area -->
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Transparent Pricing</h2>
            <p>Borderless account pricing</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Pricing Area -->
<div class="pricing-area ptb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Basic</h3>
                        <p>Get your business up and running</p>
                    </div>
                    <div class="price">
                        $0 <span>/m</span>
                    </div>
                    <div class="buy-btn">
                        <a routerLink="/" class="btn btn-primary">Choose this plan</a>
                    </div>
                    <ul class="pricing-features">
                        <li><i class="fas fa-check"></i> 100 outgoing transfers/mo</li>
                        <li><i class="fas fa-check"></i> 5 Klob Users </li>
                        <li><i class="fas fa-check"></i> 2 Business Mastercards</li>
                        <li><i class="fas fa-check"></i> Premium Support</li>
                        <li><i class="fas fa-check"></i> International payments</li>
                        <li><i class="fas fa-check"></i> Accounting integrations</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Advanced</h3>
                        <p>Get your business up and running</p>
                    </div>
                    <div class="price">
                        $12 <span>/m</span>
                    </div>
                    <div class="buy-btn">
                        <a routerLink="/" class="btn btn-primary">Choose this plan</a>
                    </div>
                    <ul class="pricing-features">
                        <li><i class="fas fa-check"></i> 100 outgoing transfers/mo</li>
                        <li><i class="fas fa-check"></i> 5 Klob Users </li>
                        <li><i class="fas fa-check"></i> 2 Business Mastercards</li>
                        <li><i class="fas fa-check"></i> Premium Support</li>
                        <li><i class="fas fa-check"></i> International payments</li>
                        <li><i class="fas fa-check"></i> Accounting integrations</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Premium</h3>
                        <p>Get your business up and running</p>
                    </div>
                    <div class="price">
                        $24 <span>/m</span>
                    </div>
                    <div class="buy-btn">
                        <a routerLink="/" class="btn btn-primary">Choose this plan</a>
                    </div>
                    <ul class="pricing-features">
                        <li><i class="fas fa-check"></i> 100 outgoing transfers/mo</li>
                        <li><i class="fas fa-check"></i> 5 Klob Users </li>
                        <li><i class="fas fa-check"></i> 2 Business Mastercards</li>
                        <li><i class="fas fa-check"></i> Premium Support</li>
                        <li><i class="fas fa-check"></i> International payments</li>
                        <li><i class="fas fa-check"></i> Accounting integrations</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Pricing Area -->

<app-account-button></app-account-button>