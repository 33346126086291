<!-- Start Navbar Area -->
<div class="navbar-area {{layoutClass}}">
    <div class="sensorai-nav">
        <div
            class="container"
            [ngClass]="{'container-fluid': router.url === '/demo-7'}"
        >
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="logo">
                    <h1 class="navbar-brand__title">SensorAI</h1>
                </a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a style="cursor: pointer;" (click)="triggerScrollTo('mission')" class="nav-link">Mission</a>
                        </li>
                        <li class="nav-item">
                            <a style="cursor: pointer;" (click)="triggerScrollTo('goals')" class="nav-link">Goals</a>
                        </li>
                        <li class="nav-item">
                            <a style="cursor: pointer;" (click)="triggerScrollTo('journey')" class="nav-link">Journey</a>
                        </li>
                        <li class="nav-item">
                            <a style="cursor: pointer;" (click)="triggerScrollTo('contact')" class="nav-link">Contact</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
