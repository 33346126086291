<!-- Start Page Title Area -->
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Right Sidebar</h2>
            <p>Our Latest News</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<div class="blog-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="blog-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog-image/blog1.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <i class="far fa-calendar-alt"></i> September 15, 2020
                                </div>
                            </div>
                            <div class="blog-post-content">
                                <h3><a routerLink="/blog-details">The security risks of changing package owners</a></h3>
                                <span>by <a routerLink="/">admin</a></span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="blog-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog-image/blog2.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <i class="far fa-calendar-alt"></i> September 17, 2020
                                </div>
                            </div>
                            <div class="blog-post-content">
                                <h3><a routerLink="/blog-details">Tips to Protecting Business and Family</a></h3>
                                <span>by <a routerLink="/">admin</a></span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="blog-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog-image/blog3.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <i class="far fa-calendar-alt"></i> September 19, 2020
                                </div>
                            </div>
                            <div class="blog-post-content">
                                <h3><a routerLink="/blog-details">Protect Your Workplace from Cyber Attacks</a></h3>
                                <span>by <a routerLink="/">admin</a></span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="blog-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog-image/blog4.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <i class="far fa-calendar-alt"></i> September 15, 2020
                                </div>
                            </div>
                            <div class="blog-post-content">
                                <h3><a routerLink="/blog-details">Business Debit Fees to Increase in 2020</a></h3>
                                <span>by <a routerLink="/">admin</a></span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="blog-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog-image/blog5.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <i class="far fa-calendar-alt"></i> September 17, 2020
                                </div>
                            </div>
                            <div class="blog-post-content">
                                <h3><a routerLink="/blog-details">10 Tips To Reduce Your Card Processing Costs</a></h3>
                                <span>by <a routerLink="/">admin</a></span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="blog-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog-image/blog6.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <i class="far fa-calendar-alt"></i> September 19, 2020
                                </div>
                            </div>
                            <div class="blog-post-content">
                                <h3><a routerLink="/blog-details">PayPal Here Card Reader Review 2020</a></h3>
                                <span>by <a routerLink="/">admin</a></span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                            <a routerLink="/" class="page-numbers">1</a>
                            <span class="page-numbers current" aria-current="page">2</span>
                            <a routerLink="/" class="page-numbers">3</a>
                            <a routerLink="/" class="page-numbers">4</a>
                            <a routerLink="/" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="fas fa-search"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_klob_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">June 10, 2020</time>
                                <h4 class="title usmall"><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">June 21, 2020</time>
                                <h4 class="title usmall"><a routerLink="/blog-details">I Used The Web For A Day On A 50 MB Budget</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">June 30, 2020</time>
                                <h4 class="title usmall"><a routerLink="/blog-details">How To Create A Responsive Popup Gallery?</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>
                        <ul>
                            <li><a routerLink="/blog-details">How to Become a Successful Entry Level UX Designer</a></li>
                            <li><a routerLink="/blog-details">How to start your business as an entrepreneur</a></li>
                            <li><a routerLink="/blog-details">How to be a successful entrepreneur</a></li>
                            <li><a routerLink="/blog-details">10 Building Mobile Apps With Ionic And React</a></li>
                            <li><a routerLink="/blog-details">Protect your workplace from cyber attacks</a></li>
                        </ul>
                    </div>
                    <div class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>
                        <ul>
                            <li><a routerLink="/">May 2020</a></li>
                            <li><a routerLink="/">April 2020</a></li>
                            <li><a routerLink="/">June 2020</a></li>
                        </ul>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/">Business</a></li>
                            <li><a routerLink="/">Privacy</a></li>
                            <li><a routerLink="/">Technology</a></li>
                            <li><a routerLink="/">Tips</a></li>
                            <li><a routerLink="/">Uncategorized</a></li>
                        </ul>
                    </div>
                    <div class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>
                        <ul>
                            <li><a routerLink="/">Log in</a></li>
                            <li><a routerLink="/">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">WordPress.org</a></li>
                        </ul>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Klob <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Area -->

<app-account-button></app-account-button>