import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    constructor(
        public router: Router,
        private scrollToService: ScrollToService
    ) { }

    ngOnInit() {}

    triggerScrollTo(navItem: string) {
        const config: ScrollToConfigOptions = {
            target: navItem,
            duration: 100,
            easing: 'easeOutElastic',
            offset: -110
        };
        this.scrollToService.scrollTo(config);
    }

}
