<!-- Start Feedback Area -->
<div class="feedback-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>What customers say about Us</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="feedback-slides">
            <div class="client-feedback">
                <div>
                    <ngx-slick-carousel
                        class="carousel feedback"
                        #slickModal="slick-carousel"
                        [config]="imagesSlider">
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client1.jpg" alt="image">
                                </div>
                                <h3>John Lucy</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client2.jpg" alt="image">
                                </div>
                                <h3>John Smith</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client3.jpg" alt="image">
                                </div>
                                <h3>Maxwel Warner</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client4.jpg" alt="image">
                                </div>
                                <h3>Ross Taylor</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client5.jpg" alt="image">
                                </div>
                                <h3>James Anderson</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client1.jpg" alt="image">
                                </div>
                                <h3>Steven Smith</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client2.jpg" alt="image">
                                </div>
                                <h3>Steven Lucy</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client3.jpg" alt="image">
                                </div>
                                <h3>John Terry</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
            <div class="client-thumbnails">
                <div>
                    <ngx-slick-carousel
                        class="carousel thumbs"
                        #slickModal="slick-carousel"
                        [config]="thumbnailsSlider">
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client1.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client2.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client3.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client4.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client5.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client1.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client2.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client3.jpg" alt="client"></div>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <button class="prev-arrow slick-arrow">
                    <i class="fas fa-arrow-left"></i>
                </button>
                <button class="next-arrow slick-arrow">
                    <i class="fas fa-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<!-- End Feedback Area -->