<!-- Start Main Banner Area -->
<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <div>
                    <h1>Digitazing water</h1>
                    <p>Digital solutions to save planet, resources, lives, and the planet.</p>
                    <a routerLink="/contact" class="btn btn-primary">Show more</a>
                    </div>
                    <img class="main-banner-content__img" src="assets/img/main-banner-content-img.JPG" alt="main image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->
<!-- Start Featured Boxes Area -->
<div class="information-area bg-f7fafd">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-sm-6 col-md-6 mb-5 mb-lg-0">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="icon-lightbulb"></i>
                    </div>
                        <div>
                            <h3>Artificial Intelligence</h3>
                            <p>or the decision support.</p>
                     </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6 mb-5 mb-lg-0">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="icon-adult"></i>
                    </div>
                        <div>
                            <h3>IoT Sensors</h3>
                            <p>for telemetry and control.</p>
                        </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="icon-headphones"></i>
                    </div>
                        <div>
                            <h3>Advisory Services</h3>
                            <p>to help you reach your goals.</p>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Featured Boxes Area -->

<!-- Start Services Area -->
<div id="mission" class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Our mission- why we care</h2>
                    <div class="bar"></div>
                    <ul class="services-list">
                        <li><span>Changing climate is becoming an increasingly severe threat to water and food safety across the globe.</span></li>
                        <li><span>Agriculture uses 70% of sweet water globally (according to FAO). We know we can help to bring it down by a quarter with our AI- supported technology</span></li>
                        <li><span>Most arid areas on Earth are poor. Digital agriculture does not have to be expensive. We provide tools tuned to everyone's needs and</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/our_mission.png" alt="image">
                    <!-- <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div id="goals" class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img class="our-goal__img" src="assets/img/our_goal.png" alt="image">
                    <!-- <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div> -->
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Our goal- what we do</h2>
                    <div class="bar"></div>
                    <ul class="services-list">
                        <li><span>Serving the environment by limiting excess water use and water pollution from over-irrigating.</span></li>
                        <li><span>Serving the farmers to optimize their irrigation practices to save water while not harming the profits. This difficult balance is achieved with advanced, intelligent algorithms bound to smart sensing technologies.</span></li>
                        <li><span>Serving the crops in difficult times of water shortage to irrigate as less as possible for as long as possible to keep plants alive through extreme droughts.</span></li>
                        <li><span>Educating everyone, especially farmers and decision makers about the importance of good water governance and newest technologies as means to proactively prepare for climate change.  </span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70 ">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="single-payment-experience-box">
                    <div class="icon m-md-0 icon--red">
                        <i class="fas fa-chart-line"></i>
                    </div>
                    <h3 class="ms-md-3">R&D Projects</h3>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="single-payment-experience-box">
                    <div class="icon m-md-0 icon--yellow">
                        <i class="fab fa-audible"></i>
                    </div>
                    <h3 class="ms-md-3">IoT Sensors</h3>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="single-payment-experience-box">
                    <div class="icon m-md-0 icon--blue">
                        <i class="fas fa-credit-card"></i>
                    </div>
                    <h3 class="ms-md-3">Advisory Services</h3>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- End Services Area -->

<!-- Start Services Area -->
<div id="journey" class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>How we will get there</h2>
                    <div class="bar"></div>
                    <p>The journey has started! The R&D projects</p>
                    <ul class="services-list">
                        <li><span>Big Data & Analytics</span></li>
                        <li><span>AI based decision support</span></li>
                        <li><span>Precision watering maps</span></li>
                        <li><span>Virtual drivers</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <!-- <div class="image">
                    <img src="assets/img/3.png" alt="image"> -->
                    <!-- <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div> -->
                </div>
            </div>
        </div>
    </div>
<!-- End Services Area -->

<!-- Start Contact Area -->
<div id="contact" class="contact-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>Interested? Drop us a message</h2>
            <div class="bar"></div>
        </div>
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-lg-5 col-md-12 ">
                <div class="contact-info">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            <span>Address</span>
                            SensorAI sp z o.o. <br> ul. Igncego Mościckiego 1 <br> 24-100 Puławy
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-envelope"></i>
                            </div>
                            <span>Email</span>
                            <a href="mailto:contact@sensorai.eu"> contact@sensorai.eu</a>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-phone-volume"></i>
                            </div>
                            <span>Phone</span>
                            <a href="tel:+44587154756"> +48 000 000 000</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-7 col-md-12 mb-5">
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Phone">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</div>
