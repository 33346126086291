<!-- Start Page Title Area -->
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>How It Works</h2>
            <p>All the tools you need</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<div class="currency-transfer-provider-with-background-color">
            
    <!-- Start Money Transfer Area -->
    <div class="ctp-money-transfer-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>How Does Money Transfer Work?</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Create a free account</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/customer.png" alt="image">
                            <div class="number">01</div>
                        </div>
                        <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. vivamus suscipit tortor eget felis porttitor volutpat. Sed porttitor lectus nibh.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Send your funds</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/profits.png" alt="image">
                            <div class="number">02</div>
                        </div>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat. sed porttitor lectus nibh. Donec rutrum congue leo eget malesuada.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Track your transfer</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/data.png" alt="image">
                            <div class="number">03</div>
                        </div>
                        <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. vivamus suscipit tortor eget felis porttitor volutpat.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Money Transfer Area -->

    <!-- Start Protec Area -->
    <div class="ctp-protec-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>We Don't Just Save Your Money</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>01</span>
                            </div>
                            <h3>Personal Service</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>02</span>
                            </div>
                            <h3>Efficient Transfers</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Protec Area -->

    <!-- Start Send Money Online Area -->
    <div class="ctp-send-money-online-area ptb-100 jarallax"  data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-7 col-md-12">
                    <div class="ctp-send-money-online-content">
                        <h1>There are only a few steps you can take to send money online</h1>
                        <ul class="ctp-list">
                            <li> 
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Move money on-the-go
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Currency wallets
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Set rate alerts
                            </li>
                        </ul>
                        <div class="video-view">
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                <i class="fas fa-play"></i> 
                                <span>See how it works</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <form class="ctp-send-money-online-form">
                        <div class="form-header">
                            <span>Exchange Rate</span>
                            <h3>1 GBP = 1.29356 USD</h3>
                        </div>
                        <div class="form-content">
                            <div class="form-group">
                                <label>Delivery Method</label>
                                <select>
                                    <option>Bank Transfer</option>
                                    <option>Send Money</option>
                                    <option>Interest Money</option>
                                    <option>Invest Money</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>You Transfer</label>
                                <input type="text" class="form-control" autocomplete="off" value="100">
                                <div class="amount-currency-select">
                                    <select>
                                        <option>GBP</option>
                                        <option>USD</option>
                                        <option>EUR</option>
                                        <option>BRL</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group zero">
                                <label>Recipient Gets</label>
                                <input type="text" class="form-control" autocomplete="off" value="129.35">
                                <div class="amount-currency-select">
                                    <select>
                                        <option>USD</option>
                                        <option>GBP</option>
                                        <option>EUR</option>
                                        <option>BRL</option>
                                    </select>
                                </div>
                            </div>
                            <div class="info">
                                <p><span>Delivery Time:</span> 1 Working Days</p>
                            </div>
                            <button type="submit" class="btn btn-primary">Send Now</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- End Send Money Online Area -->

    <!-- Start Reviews Area -->
    <div class="ctp-reviews-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Ratings And Reviews</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-reviews-box">
                        <ul class="rating">
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <span>Reviews 15</span>
                            </li>
                        </ul>
                        <h3>Great exchange rates</h3>
                        <p>Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum leo eget malesuada.</p>
                        <h4>Alisha Ingram <span>(CEO & Founder)</span></h4>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-reviews-box">
                        <ul class="rating">
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <span>Reviews 10</span>
                            </li>
                        </ul>
                        <h3>Safe and secure</h3>
                        <p>Proin eget tortor risus. Cras ultricies ligula sed magna dictum porta. lorem ipsum dolor sit amet, consectetur adipiscing elit. mauris blandit aliquet elit.</p>
                        <h4>Sharon Bass <span>(Manager)</span></h4>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-reviews-box">
                        <ul class="rating">
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <span>Reviews 16</span>
                            </li>
                        </ul>
                        <h3>Award-winning service</h3>
                        <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. praesent sapien massa, convallis a pellentesque nec, egestas non nisi. proin eget tortor risus.</p>
                        <h4>Nancy Artiaga <span>(Founder at Klob)</span></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Reviews Area -->

</div>