<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <p>Our Latest News</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<div class="blog-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog-image/blog1.jpg" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 15, 2020
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/blog-details">The security risks of changing package owners</a></h3>
                        <span>by <a routerLink="/">admin</a></span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog-image/blog2.jpg" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 17, 2020
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/blog-details">Tips to Protecting Business and Family</a></h3>
                        <span>by <a routerLink="/">admin</a></span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog-image/blog3.jpg" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 19, 2020
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/blog-details">Protect Your Workplace from Cyber Attacks</a></h3>
                        <span>by <a routerLink="/">admin</a></span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog-image/blog4.jpg" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 15, 2020
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/blog-details">Business Debit Fees to Increase in 2020</a></h3>
                        <span>by <a routerLink="/">admin</a></span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog-image/blog5.jpg" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 17, 2020
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/blog-details">10 Tips To Reduce Your Card Processing Costs</a></h3>
                        <span>by <a routerLink="/">admin</a></span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog-image/blog6.jpg" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 19, 2020
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/blog-details">PayPal Here Card Reader Review 2020</a></h3>
                        <span>by <a routerLink="/">admin</a></span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                    <a routerLink="/" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/" class="page-numbers">3</a>
                    <a routerLink="/" class="page-numbers">4</a>
                    <a routerLink="/" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Area -->

<app-account-button></app-account-button>