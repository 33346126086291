<!-- Start Ready To Talk Area -->
<div class="ready-to-talk">
    <div class="container">
        <div class="ready-to-talk-content">
            <h3>Ready to talk?</h3>
            <p>Our team is here to answer your question about Klob</p>
            <a routerLink="/contact" class="btn btn-primary">Contact Us</a>
            <span><a routerLink="/">Or, get started now with a free trial</a></span>
        </div>
    </div>
</div>
<!-- End Ready To Talk Area -->